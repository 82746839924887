<template>
	<div class="container-register-sleep">
	<SWNavButton
		@click="$router.push({ name: 'forgot' })" 
		class="btn-nav-ragister"
		:size="'small'"
		>Back</SWNavButton
	>
	<div class="box-register-sleep">
		<div class="img-sleep-register">
			<img src="../../assets/img/sleepNewLogo.png" alt="" />
		</div>

		<div class="title-sleep-register">
			<p>Reset your password</p>
			<p class="subtitle">We’ll send instructions for resetting your password. If you didn’t get them, will check the email address or ask to resend the instructions.</p>
		</div>

		<div class="title-sleep-register">
			<button class="subtitle resend" @click="sendEmail"><u>Resend the instruction again</u></button>
		</div>

<!-- 		<SWButton variant="primari" size="big" @click="proceed">Continue</SWButton> -->

		<div v-if="support" class="title-sleep-support">
			<p class="subtitle">If you still haven't received an email, but you are sure that the email address or phone number is correct, please contact us:</p>
			<p class="subtitle">support@sleep-well.coach</p>
			<p class="subtitle">+38 044 256 4556</p>
		</div>

		<!-- <router-view></router-view> -->
	</div>
 </div>
 </template>
 
 <script>
 import {SWNavButton} from 'sw-ui-kit';
 import { validationMixin } from 'vuelidate';
 import { required, minLength, maxLength } from 'vuelidate/lib/validators';
 import { hasNumberPhone, hasEmail } from '../../validation/validation';
 import * as Sentry from '@sentry/vue';

 export default {

 components: {
	SWNavButton,

 },
 mixins: [validationMixin],
 

 data(){
	return {
		email: '',
		support: false
	}
 },
 validations: {
	email: { required, hasEmail },
	phone: { required, maxLength: maxLength(21), minLength: minLength(10), hasNumberPhone },
 },

async mounted(){
	console.log(this.$route.params.email)
	this.email = this.$route.params.email;

 
	if(!this.email){
		return this.$router.push({ name: 'forgot' });
	}

	setTimeout(() => {
		this.support = true;
	}, 15000);

	await this.sendEmail();
	},

	methods: {

		async sendEmail(){
			try{
				await this.$http.post(`/auth/forgot/get/email`, { email: this.email });
			} catch(e){
				console.log(e)
				Sentry.captureException(e);
			}
		}
	},
	computed:{

	}
 };
 </script>

<style lang="sass" scoped>
.name-registar
	display: flex
	min-width: 175px
	width: fit-content
	margin-bottom: 15px
.corent-margin
	margin-right: 8px
.reg-box
	display: flex
	justify-content: center
.reg-box:nth-child(1)
	margin-right: 20px

.reg-box
	@media screen and (max-width:380px)
		margin-top: -10px
.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 15px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
	text-align: center
.title-sleep-support
	text-align: left
.subtitle
	font-family: 'Roboto', sans-serif
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	color: #11151F
	margin-top: 16px
	margin-bottom: 16px
.resend
	font-weight: 400
	font-size: 12px
	line-height: 15.6px
	color: #11151F

.paragraf-register
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	text-align: center
	margin-top: 16px
	margin-bottom: 16px
	@media screen and (max-width:380px)
		margin-bottom: 5px
.paragraf-current
	font-weight: 500
	line-height: 18.2px
	text-align: center
	color: #828282
	cursor: pointer
	padding: 15px
.active
	color: #11151F
	text-decoration: underline
.paragraf-current-one
	font-weight: 500
	line-height: 18.2px
	text-align: center
	padding-top: 15px
.footer-sleep-register
	color: #828282
	font-size: 12px
	font-weight: 400
	line-height: 16px

.button-social
	display: flex
	justify-content: space-between
.btn-reg
	width: 100%
	font-size: 14px
	font-weight: 400
.btn-reg:nth-child(2)
	margin-right: 10px
	margin-left: 10px
.register-input
	margin-bottom: 32px
	@media screen and (max-width:380px)
		margin-bottom: 10px
.margin-inp
	margin-bottom: 16px
.btn-nav-ragister
	margin-bottom: 20px
	font-weight: 400
	margin-top: 64px

@media screen and (max-width:1024px)
	.btn-nav-ragister
		margin-top: 16px
</style>